import { Outlet } from "react-router-dom";
import MyHeader from "../components/MyHeader";
import MyFooter from "../components/MyFooter";
// import "../styles/ebusinesscards.scss";
const Layout = () => {
    return (
        <>
            <MyHeader />
            <div className="page-content">
                <Outlet />
            </div>
            <MyFooter />
        </>
    )
};

export default Layout;