import React, { useState, useEffect } from "react";
// import "../styles/teammember.scss";
// import "../styles/loader.css";
import { useParams } from "react-router-dom";
import Map from "../components/Map";
import shareIcon from "../assets/img/share-icon-connect.svg";
import plusIcon from "../assets/img/add-icon-connect.svg";
function MemberDetail() {
    let params = useParams();
    let userId = params.userId;
    const [mem, setMem] = useState([]);
    const [Loaded, setLoaded] = useState(false);
    const noMapID = ["504","505","506"];
    switch (userId) {
        case "74":
            userId = "710";
            break;
        case "71":
            userId = "699";
            break;
        case "143":
            userId = "714";
            break;
        case "414":
            userId = "712";
            break;
        case "471":
            userId = "711";
            break;
        case "506":
            userId = "717";
            break;
        case "505":
            userId = "718";
            break;    
        case "504":
            userId = "719";
            break;        
        default:
            break;
    }
    useEffect(() => {
        fetch('https://connectfacilities.com.au/wp-json/wp/v2/ebusinesscard/'+userId, {credentials: 'include'})
        .then(res => res.json())
        .then((res) => {
            setMem(res);
            setLoaded(true);
            // console.log(res);
        });
    }, [userId]);
    if(!Loaded) {
        return (
            <div className='container loading-container'>
                <div className='loader'>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--text'></div>
                </div>
            </div>
        )
    }
    let memberCustomFields = mem["toolset-meta"]["ebusinesscard-fields"];
    const copy = (e) => {
        e.preventDefault();
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        // Show copied message
        let existingCopied = document.getElementById('copiedID');
        if(existingCopied) {
            existingCopied.remove();
        }
        const copied = document.createElement('p');
        copied.className = 'copied';
        copied.id = 'copiedID';
        copied.innerHTML = 'URL copied to clipboard!';
        document.getElementById('share-wrapper').appendChild(copied);
    }

    const showMaps = (id) => {
        if(noMapID.includes(id)) {
            return true;
        }
        return false;
    }
    const firstName = memberCustomFields["card-first-name"]["raw"];
    const lastName = memberCustomFields["card-last-name"]["raw"];
    const jobTitle = memberCustomFields["card-job-title"]["raw"];
    const photo = memberCustomFields["card-image"]["raw"];
    const email = memberCustomFields["card-email"]["raw"];
    const phone = memberCustomFields["card-phone"]["raw"];
    return (
        <>
            <div className="container">
                <div className="team-member-detail-wrapper">               
                    <div className="photo" style={{ backgroundImage: `url(${photo})` }}></div>
                    <div className="details-container">
                        <div className="general-info">
                            <p className="name">{firstName} {lastName}</p>
                            <p className="title">{jobTitle}</p>
                            <p className="phone"><span>T</span>{phone}</p>
                            <p className="email"><span>E</span>{email}</p>
                        </div>
                        <div className="all-button-wrapper" id="share-wrapper">
                            <div className="cta-wrapper">
                                <a className="phone-cta" href={"tel:"+phone}>CALL</a>
                                <a className="email-cta" href={"mailto:"+email}>EMAIL</a>
                            </div>
                            <div className="button-wrapper">
                                <a className="vcard-cta" href={'https://connectfacilities.com.au/wp-json/businesscards/v1/vcard/'+mem["id"]}>ADD TO CONTACTS <img src={plusIcon} /></a>
                                <a className="share-cta" onClick={copy}>SHARE CONTACT <img src={shareIcon} /></a>
                            </div>
                        </div>
                    </div>                             
                    { showMaps(userId) ? "" : <div className="map-container"> <Map/></div>}
                </div>
                <a className="back-button" href="/"><span>&lt;</span><p>Back</p></a>
            </div>
        </>
    )
}
export default MemberDetail;