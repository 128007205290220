import React from "react";
function TeamMember(props) {
    const memberCustomFields = props.profile["toolset-meta"]["ebusinesscard-fields"];
    const firstName = memberCustomFields["card-first-name"]["raw"];
    const lastName = memberCustomFields["card-last-name"]["raw"];
    const jobTitle = memberCustomFields["card-job-title"]["raw"];
    const photo = memberCustomFields["card-image"]["raw"];
    const email = memberCustomFields["card-email"]["raw"];
    const phone = memberCustomFields["card-phone"]["raw"];
    const memberID = props.profile.id;
    let linkURL = window.location.href+"member-detail/"+memberID;
    let qrcodeURL = "https://api.qrserver.com/v1/create-qr-code/?data="+window.location.href+"member-detail/"+memberID+"&color=186-188-190&bgcolor=16-57-57";
    return(
        <div className="aMember">
            <a href={linkURL} ><div className="aMember__photo" style={{ backgroundImage: `url(${photo})` }}></div></a>
            <div>
                <div>
                    <h2 className="aMember__name">{firstName} {lastName}</h2>
                    <p className="aMember__title">{jobTitle}</p>
                    <a className="aMember__cta" href={"tel:"+phone}><span>T</span>{phone}</a>
                    <a className="aMember__cta" href={"mailto:"+email}><span>E</span>{email}</a>
                </div>
                <a className="qrcode-link" href={linkURL}><img alt="profile qr code" src={qrcodeURL} /></a>
            </div>
        </div>
    );
}
export default TeamMember;