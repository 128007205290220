import React, { useState, useEffect, useRef } from "react";
import TeamMember from "../components/TeamMember";

function Homepage() {
    const [profiles, setProfiles] = useState([]);
    const [DataisLoaded, setLoaded] = useState(false);
    const fullList = useRef([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_WP_JSON_URL + "/wp-json/wp/v2/ebusinesscard?per_page=30", {
            credentials: 'include'
          })
            .then(res => res.json())
            .catch(err => console.log(err))

        .then((json) => {
            console.log(json);
            setProfiles(json);
            setLoaded(true);
            fullList.current = json;
        });
    }, []);
        
    if(!DataisLoaded) 
        return <div className='container loading-container'>
            <div className='loader'>Loading...</div>
        </div>;
    
    return (
        <div className="ebusinesscard-page">
            <div className="container">
                {profiles.map((item, key) => (
                    <TeamMember profile={item} key={key} />
                ))}
            </div>            
        </div>
    )
}
export default Homepage;