import React from "react";

function MyFooter() {
    let date = new Date();
    return (
        <>
        <div className="footer">   
            <div className="container footer-links">
                <p>Copyright {date.getFullYear()} Connect Facilities  </p>
                <ul>
                    {/* <li><a href="#" target="_blank">Privacy Policy</a></li>
                    <li><a href="#" target="_blank">Terms &amp; Conditions</a></li> */}
                    <li><a href="https://connectfacilities.com.au/our-services/" target="_blank">Facilities Management Services Melbourne</a></li>
                    <li><a href="https://connectfacilities.com.au/our-services/" target="_blank">Commercial Building Management</a></li>
                    <li><a href="https://connectfacilities.com.au/our-services/" target="_blank">Contractor Management</a></li>
                    
                </ul>
            </div>
        </div>
        </>
    )
}

export default MyFooter;