import React from "react"

function ErrorPage() {
    return (
        <>
            <h1>Page not found</h1>
            <a href="/">Go back to Home</a>
        </>           
    )
}
export default ErrorPage;
