import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Homepage";
import MemberDetail from "./pages/MemberDetailPage";
import ErrorPage from "./pages/ErrorPage";
import Layout from "./pages/Layout";
import "./styles/main.scss";


class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<HomePage />}/>
            <Route path="member-detail/:userId" element={<MemberDetail />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;
  